import Stage from "../../../vendorjs/Stage";
import Section from '../Section';

export default class DigitalConsultancy extends Section
{
  constructor()
  {
    super(...arguments);


  }


}
