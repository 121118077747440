import Stage from "../../vendorjs/Stage";

export default class Section
{
  constructor(id, pos, holder, carrousel)
  {
    // console.log('init', this);

    this.id = id;
    this.pos = pos;
    this.holder = holder;
    this.carrousel = carrousel;

  }

  getSlideTl()
  {
    if (this.slide_tl) {
      this.slide_tl.kill();
      this.slide_tl.invalidate();
      this.slide_tl = null;
    }

    this.slide_tl = new TimelineLite();
    this.slide_tl.to(this, 2, { foo:1 }, 0);

    let _center = 1;
    let _delay = .25;
    let _dur = .25;

    this.slide_tl.fromTo(this.holder, _dur, {autoAlpha:0}, { autoAlpha: 1, ease: Power1.easeInOut }, _center - _delay - _dur);
    this.slide_tl.to(this.holder, _dur, { autoAlpha: 0, ease: Power1.easeInOut }, _center + _delay);

    return this.slide_tl;

  }


  resize()
  {

  }

}
