export function home($, gsap) {


  // Cursor Follower
  // gsap.set($('.ball'), { xPercent: -50, yPercent: -50 });

  // const ball = $('.ball');
  // const pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
  // const mouse = { x: pos.x, y: pos.y };
  // const speed = 0.2;

  // const xSet = gsap.quickSetter(ball, "x", "px");
  // const ySet = gsap.quickSetter(ball, "y", "px");

  // window.addEventListener("mousemove", e => {
  //   mouse.x = e.x;
  //   mouse.y = e.y;
  // });

  // gsap.ticker.add(() => {
  //   // adjust speed for higher refresh monitors
  //   const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio());

  //   pos.x += (mouse.x - pos.x) * dt;
  //   pos.y += (mouse.y - pos.y) * dt;
  //   xSet(pos.x);
  //   ySet(pos.y);
  // });

  // Masking Code
  const container = $("#home--ball");
  const mask = $(".ball");
  const maskContent = $(".ball-content");


  GSAP.TweenLite.set(maskContent, {
    width: container.offsetWidth,
    height: container.offsetHeight
  });

  if ($(document).innerWidth() > 1024) {
    $(container).on("mousemove", function (e) {
      const x = e.pageX - 100;
      const y = e.pageY - 100;

      GSAP.TweenLite.set(mask, {
        x: x,
        y: y,
        opacity: 1
      });

      GSAP.TweenLite.set(maskContent, {
        x: -x,
        y: -y
      });
    });
  }

  $("#home--show").on('click', function() {
    $(mask).addClass("active");
    $('.menu--desktop').hide()
    $("#app").css("padding-left", "0");
  })
  
  $("#home--hide").on('click', function() {
    $(mask).removeClass("active");
    $('.menu--desktop').show()
    $("#app").css("padding-left", "5px");
  })

  $(".link-effect").hover(function () {
    $(this).parents().find('.home--circle').addClass('blur');
  }, function () {
    $(this).parents().find('.home--circle').removeClass('blur');
  });

}
