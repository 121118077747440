export function header($) {
    var headerTl = new TimelineMax(),
        logo = $('.logo'),
        hamline = $('.hamburger-wrapper'),
        hbMinTO;

    if ($(window).width() > 768) {
        headerTl.staggerTo('.line1', 0.1, { width: "50px", ease: Elastic.easeOut })
            .staggerTo('.line2, .line4', 0.1, { width: "30px", ease: Elastic.easeOut })
            .staggerTo('.line3', 0.1, { width: "50px", ease: Elastic.easeOut })
            .staggerTo('.line4', 0.1, { width: "20px", ease: Elastic.easeOut });

    } else {
        headerTl.staggerTo('.line1', 0.1, { width: "40px", ease: Elastic.easeOut })
            .staggerTo('.line2, .line4', 0.1, { width: "20px", ease: Elastic.easeOut })
            .staggerTo('.line3', 0.1, { width: "40px", ease: Elastic.easeOut })
            .staggerTo('.line4', 0.1, { width: "20px", ease: Elastic.easeOut });
    }



    headerTl.to(logo, 0.5, { y: 0, ease: Expo.easeOut });
    headerTl.to(hamline, 0.5, { ease: Expo.easeOut });

    $(hamline).click(function(e) {
        if ($(window).width() > 768) {
            if ($('.menu--desktop').hasClass('open')) {
                $(this).find('.hamburger').removeClass('open');
                $('.menu--desktop').removeClass('open');
                $('body').removeClass('block');
                TweenMax.to($('.menu--desktop'), 0.6, {
                    x: '-100%',
                    ease: Expo.easeInOut,
                    onComplete: function() {
                        TweenMax.to($('.pivot-outlined'), 1, { x: '100%', ease: Expo.easeInOut });
                        TweenMax.to($('.imagechange'), 1, { y: '100%', ease: Expo.easeInOut });
                    }
                });
                TweenLite.to($('#menu-morph'), 0.6, { morphSVG: $('#morph-shape').attr('data-morph-return'), ease: Expo.easeInOut });
            } else {
                $(this).find('.hamburger').addClass('open');
                $('.menu--desktop').addClass('open');
                $('body').addClass('block');
                TweenMax.to($('.menu--desktop'), 0.6, {
                    x: '0%',
                    ease: Expo.easeInOut,
                    onComplete: function() {
                        TweenMax.to($('.pivot-outlined'), 1, { x: '50%', ease: Expo.easeInOut });
                        TweenMax.to($('.imagechange'), 1, { y: '0%', ease: Expo.easeInOut });
                    }
                });
                TweenLite.to($('#menu-morph'), 0.8, { morphSVG: $('#morph-shape').attr('data-morph-open'), ease: Power1.easeInOut });
            }
        } else {

            var deviceWidth = $(window).width() - 80;
            if ($('.menu--desktop').hasClass('open')) {
                $(this).find('.hamburger').removeClass('open');
                $('.menu--desktop').removeClass('open');
                $('body').removeClass('block');
                TweenMax.to(".menu--desktop", 1, { width: "5px", ease: Power3.easeOut })
                TweenMax.to("#app, footer", 1, { x: 0, ease: Power3.easeOut })
                TweenMax.to(".menu--desktop_inner", 0.2, { opacity: 0, ease: Power3.easeOut })

            } else {
                $(this).find('.hamburger').addClass('open');
                $('.menu--desktop').addClass('open');
                $('body').addClass('block');
                TweenMax.to("#app, footer", 1, { x: deviceWidth + 'px', ease: Power3.easeOut })
                TweenMax.to(".menu--desktop", 1, { width: (deviceWidth + 5), ease: Power3.easeOut })
                TweenMax.to(".menu--desktop_inner", 0.2, { opacity: 1, ease: Power3.easeOut })


            }
        }

    });

    if ($(window).width() <= 768) {
        $('a').on('click', function() {
            $('.menu--desktop, .hamburger').removeClass('open');
        });
    }

    var height = $(window).innerHeight() - 100;
    $('.main--menu-mobile').css('height', height);

    var
    /* circleInner = $('.cursor__inner--circle'),
           circleDot = $('.cursor__inner--dot'), */
        linkArrow = $('.cursor .link-arrow');
    $(window).on('mousemove', function(e) {
        // TweenMax.to(circleInner, 0.3, { x: (e.pageX - (circleInner.outerWidth(true) / 2)), y: (e.pageY - (circleInner.outerHeight(true) / 2)) });
        // TweenMax.to(circleDot, 0.01, { x: (e.pageX - (circleDot.outerWidth(true) / 2)), y: (e.pageY - (circleDot.outerHeight(true) / 2)) });
        TweenMax.to(linkArrow, 0.01, { x: (e.pageX - 4), y: (e.pageY - 3), scale: 2.5 });
    });

    // $('[mouse-focus], a').not('.no-hover').hover(function() {
    //   TweenMax.to(circleInner, 0.5, { className: '+=hover', width: 35, height: 35, ease: "elastic.out(1, 0.3)" });
    //   linkArrow.addClass('show');
    // }, function() {
    //   TweenMax.to(circleInner, 0.5, { className: '-=hover', width: 23, height: 23, ease: "elastic.out(1, 0.3)" });
    //   linkArrow.removeClass('show');
    // });

    var nav = $('.hamburger-wrapper.sticky');
    $(window).scroll(function() {
        var scroll = $(window).scrollTop();
        if (scroll >= 50) {
            nav.addClass("show").removeClass('min');
            clearTimeout(hbMinTO);
            // hbMinTO = setTimeout(function() {
            //   nav.addClass("min");
            // }, 3000);
        } else {
            clearTimeout(hbMinTO);
            nav.removeClass("show");
        }
    });




}
