import Stage from "../../../vendorjs/Stage";
import Section from '../Section';

export default class PerformanceMarketing extends Section
{
  constructor()
  {
    super(...arguments);

  }

}
