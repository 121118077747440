import Stage from "../../../vendorjs/Stage";

export default class FrontAnimations
{
  constructor(carrousel)
  {
    this.carrousel = carrousel;
  }

  setAnimations()
  {
    if (this.main_tl) {
      this.main_tl.kill();
      this.main_tl.invalidate();
      this.main_tl = null;
    }

    this.main_tl = new TimelineLite({ paused: true });

    this.carrousel.sections.forEach((item, i) => {

      this.main_tl.add(item.getSlideTl(), i);

    });

  }

}
