import Carrousel from "./carrousel/Carrousel";
import Stage from "../vendorjs/Stage";

export default class Service {
    constructor(id) {
        //console.log('this is home');
        this.dom = document.querySelector('.service--carrousel-wrapper');
        Stage.resize();

        this.initDesktop();
    }


    initDesktop() {
        //  console.log('this is service');
        this.swpContainer = this.dom.querySelector('.service-swiper');

        this.swiper = new Swiper.default(this.swpContainer, {
            speed: 1000,
            effect: 'slide',
            threshold: 0,
            keyboard: false,
            // mousewheel: true,
            spaceBetween: 0,
            simulateTouch:true,
            allowTouchMove:true,
            touchEventsTarget:'container',

        });

        this.carrousel = new Carrousel(this);

        //  this.swiper.on('transitionStart', this.onRelease);
        // console.log(this.carrousel.dom.dataset.slide);
        if (this.carrousel.dom.dataset.slide) {
            // console.log('if');
            this.carrousel.setSectionById(this.carrousel.dom.dataset.slide);
        } else {
            // console.log('else');
            this.carrousel.initSection(0);
            // console.log(this.carrousel.initSection(0));
        }

    }




    destroy() {
        super.destroy();
    }

    resize() {
        super.resize();
        if (this.carrousel)
            this.carrousel.resize();

    }

    update() {
        super.update();
    }

}

var sliderWrapper = jQuery("#service--carrousel-wrapper");
var initialSlide = null;
export function serviceScroll($) {
    sliderWrapper = $("#service--carrousel-wrapper");
    initialSlide = 1;
    var isMac = true;
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('mac') < 0) {
        isMac = false;
    }
    
    if (isMac) {
        setTimeout(() => {
            $('html, body').css({
                overflow: 'initial'
            });
        }, 1000);
    }

    //slide on mouse scroll
    var lastST = 0;
    $(window).on('scroll', function() {
        if ($('[data-barba-namespace]').attr('data-barba-namespace') === 'services') {
            var st = $(window).scrollTop();
            if (st > lastST) {
                console.log('down');
                if ($(window).scrollTop() >= sliderWrapper.offset().top - 100 && initialSlide !== $('.carrousel__bullets__bullet-holder').length) {
                    if (isMac) {
                        $('html, body').stop().animate({
                            scrollTop: sliderWrapper.offset().top
                        }, 0).css({
                            overflow: 'hidden'
                        });
                        // $("body").getNiceScroll(0).doScrollTop(sliderWrapper.offset().top, 0);
                    } else {
                        $("body").getNiceScroll(0).doScrollTop(sliderWrapper.offset().top, 0);
                    }
                }
            } else {
                console.log('up');

                if ($(window).scrollTop() <= sliderWrapper.offset().top && initialSlide !== 1) {
                    if (isMac) {
                        $('html, body').stop().animate({
                            scrollTop: sliderWrapper.offset().top
                        }, 0).css({
                            overflow: 'hidden'
                        });
                        // $("body").getNiceScroll(0).doScrollTop(sliderWrapper.offset().top, 0);
                    } else {
                        $("body").getNiceScroll(0).doScrollTop(sliderWrapper.offset().top, 0);
                    }
                }
            }
            lastST = st;
          
        }
    });
    $('#service--carrousel-wrapper').bind("wheel mousewheel onmousewheel",
        _.debounce(
            function(e) {
                if ($('[data-barba-namespace]').attr('data-barba-namespace') === 'services') {
                    // change slide start
                    if (e.originalEvent.deltaY > 0) {
                        // console.log('down');
                        if (initialSlide < $('.carrousel__bullets__bullet-holder').length && $(window).scrollTop() == sliderWrapper.offset().top) {
                            $('.carrousel__bullets__bullet-holder:nth-child(' + (initialSlide + 1) + ') .carrousel__bullets__bullet').click();
                        }
                        if (initialSlide == $('.carrousel__bullets__bullet-holder').length) {
                            $('html, body').css({
                                overflow: 'initial'
                            });
                        }
                    }
                    if (e.originalEvent.deltaY < 0) {
                        // console.log('up');
                        if (initialSlide > 0 && $(window).scrollTop() == sliderWrapper.offset().top) {
                            $('.carrousel__bullets__bullet-holder:nth-child(' + (initialSlide - 1) + ') .carrousel__bullets__bullet').click();
                        }
                        if (initialSlide == 1) {
                            $('html, body').css({
                                overflow: 'initial'
                            });
                        }
                    }
                    // change slide end
                }
            }, 40, true
        )
    );
    $('#service--carrousel-wrapper').bind("wheel mousewheel onmousewheel",
        _.throttle(
            function(e) {
                if ($('[data-barba-namespace]').attr('data-barba-namespace') === 'services') {
                    // disable scroll on slider section start
                    if (e.originalEvent.deltaY > 0) {
                        // console.log('down');
                        if ($(window).scrollTop() >= sliderWrapper.offset().top && initialSlide !== $('.carrousel__bullets__bullet-holder').length) {
                            $(window).scrollTop(sliderWrapper.offset().top);
                            e.preventDefault();
                            return false;
                        }
                    }
                    if (e.originalEvent.deltaY < 0) {
                        // console.log('up');
                        if ($(window).scrollTop() <= sliderWrapper.offset().top && initialSlide !== 1) {
                            $(window).scrollTop(sliderWrapper.offset().top);
                            e.preventDefault();
                            return false;
                        }
                    }
                    // disable scroll on slider section end
                }

            }, 20, {
                leading: true,
                trailing: true
            }
        )
    );



    // set slide increment
    $('.carrousel__bullets__bullet-holder').each(function(idx, ele) {
        var idx = idx + 1;
        $(this).on('click', function() {
            initialSlide = idx;
        });
    });

    $('[pagination-text]').each(function(idx, ele) {
        $('.carrousel__bullets__bullet-holder:nth-child(' + (idx + 1) + ')').append('<small class="pagination-text">' + $(this).attr('pagination-text') + '</small>');
        console.log($('.carrousel__bullets__bullet-holder:nth-child(' + (idx) + ')'));
    });

}