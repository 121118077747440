export function cultureSlider($) {
    var swiperCulture = new Swiper.default('.culture--slider', {
        spaceBetween: 0,
        speed: 1500,
        slidesPerView: 1,
        centeredSlides: true,
        loop: true,
        autoplay: {
            delay: 3500,
            disableOnInteraction: false,
        },
        navigation: {
            nextEl: '.culture--navigation .culture--next',
            prevEl: '.culture--navigation .culture--prev',
        },
        breakpoints: {
            480: {
                slidesPerView: 3,
            },
            640: {
                slidesPerView: 3,
            },
            769: {
                slidesPerView: 4,
            }
        }
    });

    createMask();

    $(window).on('resize', function() {
        createMask();
    });

    function createMask() {
        $('.culture--slider .pivot-circle-inner, .culture--slider .slide--img, .culture--slider .slide--img img').css({
            width: $('.mask-container img').height(),
            height: $('.mask-container img').height(),
            minWidth: $('.mask-container img').height(),
            minHeight: $('.mask-container img').height(),
            opacity: 1,
        });
    }

}