export function ourWork($) {
  $('.work--toolbar li a').click(function() {
    var ourClass = $(this).attr('class');
    $('.work--toolbar li').removeClass('selected');
    $(this).parent().addClass('selected');

    if(ourClass == 'all') {
      $('.work--list').children('.work--item').show();
    }
    else {
      $('.work--list').children('div:not(.' + ourClass + ')').hide();
      $('.work--list').children('div.' + ourClass).show();
    }
    return false;
  });

  $('.work--item').hover( function() {
      var dataBgColor = $(this).attr('data-bgcolor');
      $(this).find('.work--item-circle').css({ "background-color" : dataBgColor });
      TweenMax.to($(this).find('.work--item-image img'), 0.5,{scale: 0.8, y: 40, autoAlpha:1, ease: Power3.easeInOut});
      TweenMax.staggerTo($(this).find('.work--item-outside'), 0.5, {top: '40%', ease: Power4.easeOut }, 1);
      $(this).find('.work--item-outside-para.one').hide();
      $(this).find('.work--item-outside-para.two').show();
  }, function() {
    // $(this).find('.work--item-circle').css({ "background-color" : 'transparent' });
    TweenMax.to($(this).find('.work--item-image img'), 0.5,{scale:'1', y: 0, autoAlpha:1, ease: Power3.easeInOut});
    TweenMax.staggerTo($(this).find('.work--item-outside'), 0.5, {top: '130%', ease: Power4.easeOut }, 1);
    $(this).find('.work--item-outside-para.one').show();
    $(this).find('.work--item-outside-para.two').hide();
  });

}
