import Stage from "../../../vendorjs/Stage";
import Section from '../Section';

export default class BrandSolutionsStrategy extends Section
{
  constructor()
  {
    super(...arguments);


  }
}
