import Stage from "../../vendorjs/Stage";

import Bullets from './Bullets';
import BackAnimations from "./animations/BackAnimations";
import FrontAnimations from "./animations/FrontAnimations";
import DigitalConsultancy from './sections/DigitalConsultancy';
import SocialContentMarketing from './sections/SocialContentMarketing';
import Pivotconsult from './sections/Pivotconsult';
import BrandSolutionsStrategy from './sections/BrandSolutionsStrategy';
import CreativeTechnology from './sections/CreativeTechnology';
import PerformanceMarketing from './sections/PerformanceMarketing';

export default class Carrousel
{
  constructor(page)
  {
    // console.log('this is carrousel');
    this.page = page;

    this.dom = this.page.dom.querySelector('.carrousel');

    this.contents = this.dom.querySelector('.carrousel__contents');


    this.initSections();
    this.setBackgrounds();
    this.setMask();

    this.back_anim  = new BackAnimations(this);
    this.front_anim = new FrontAnimations(this);

    this.bullets = new Bullets(this);

    this.currentSection = 0;

    this._maskY = 0;
    this._progress = 0;
    this._totalItems = this.back_items.length;
    this._stepRatio = 1 / (this._totalItems + 1);
    this.resize();

  }

  initSection(index)
  {
    this.currentSection = index;
    this.progress = index / (this._totalItems - 1);
    this.page.swiper.slideTo(index, 0);
    this.bullets.select(index);
     // console.log(this.page.swiper.slideTo(index, 0));
  }

  setSection(index)
  {
    //console.log('setSection');

    if ( index != this.currentSection ) {
      this.currentSection = index;
      this.bullets.select(index);

    }
  }

  setSectionById(id)
  {
    const _id = (id == 'single');
    console.log(_id);
    this.sections.forEach(section => {
      if (section.id == _id) {
        this.initSection(section.pos);
        // console.log(this.initSection(section.pos));

        return;
      }
    });
  }

  get sectionId()
  {
    return this.sections[this.currentSection].id;
  }


  get maskY()
  {
    return this._maskY;
  }

  set maskY(value)
  {
    this._maskY = value;
    this.setMaskPos();
  }

  get progress()
  {
    return this._progress;
  }

  set progress(value)
  {
    this._progress = value;

    let r = value;
    if (r < 0) r = 0;
    else if (r > 1) r = 1;

    this.colorTL.progress(r);

    const _slideRatio = this._stepRatio + value * ( 1 - this._stepRatio * 2 );

    if (this.back_anim.main_tl) {
      this.back_anim.main_tl.progress(_slideRatio);
      this.front_anim.main_tl.progress(_slideRatio);
    }

  }


  initSections()
  {
    this.sections = [];

    const contents = Array.from(this.dom.querySelectorAll('.carrousel__content-holder'));

    contents.forEach( (item, i) => {

      let section;

      if (item.id == 'digital') {
        section = new DigitalConsultancy(item.id, i, item, this);
      } else if (item.id == 'brand') {
        section = new BrandSolutionsStrategy(item.id, i, item, this);
      } else if (item.id == 'performance') {
        section = new PerformanceMarketing(item.id, i, item, this);
      } else if (item.id == 'creative') {
        section = new CreativeTechnology(item.id, i, item, this);
      } else if (item.id == 'pivotconsult') {
        section = new Pivotconsult(item.id, i, item, this);
      } else if (item.id == 'social') {
        section = new SocialContentMarketing(item.id, i, item, this);
      }

      this.sections.push(section);

    });

  }

  setBackgrounds()
  {
    this.background = this.dom.querySelector('.carrousel__back');

    const _bgs = Array.from(this.background.querySelectorAll('.carrousel__back__item'));

    this.back_items = [];

    this.colors = [];

    _bgs.forEach( (bg, i) => {

      const tinycolor = require("tinycolor2");
      const bgColor = bg.querySelector('.carrousel__bg-color');
      const color = bgColor.style.backgroundColor;

      this.colors.push(color);

      let colorMod = tinycolor(color);

      if ( colorMod.getBrightness() < 200 )
        colorMod = colorMod.brighten(15).toString();
      else
        colorMod = colorMod.darken(151).toString();

      let bgTitle = bg.querySelector('.carrousel__bg-title');
      let bgLines = Array.from(bg.querySelectorAll('.carrousel__bg-title__line'));

      if (bgTitle) bgTitle.style.color = colorMod;

      this.back_items.push({
        holder  : bg.querySelector('.carrousel__bg-img-holder'),
        image   : bg.querySelector('.carrousel__bg-img-holder-sub'),
        imgPrlx : bg.querySelector('.carrousel__bg-img-holder-sub-2'),
        bgColor : bgColor,
        bgTitle : bgTitle,
        bgLines : bgLines
      });

      bg.style.zIndex = _bgs.length-i;

    });

    this.colorTL = new TimelineLite({ paused: true });
    this.colorTL.set($('.carrousel__bg-color'), { backgroundColor: this.colors[0] });

    this.colors.forEach((color, i) => {
      if (i > 0)
        this.colorTL.to($('.carrousel__bg-color'), 1, { backgroundColor: color, ease: Power2.easeInOut });
    });

  }

  setMask()
  {
    this.mask_holder = this.dom.querySelector('.carrousel__mask-holder');
    const mask_items = Array.from(this.mask_holder.querySelectorAll('.carrousel__back__item'));
    this.mask_backs = [];
    mask_items.forEach((item, i) => {
      this.mask_backs.push({
        resizeHolder : item.querySelector('.carrousel__bg-img-holder'),
        image        : item.querySelector('.carrousel__bg-img-holder-sub'),
        imgPrlx      : item.querySelector('.carrousel__bg-img-holder-sub-2'),
        holder       : item
      });
    });
  }

  setMaskPos()
  {
    const marginBottom = Stage.height * .033;

    TweenLite.set(this.mask_holder, {
      x: Math.round(Stage.centerX - this.maskWidth * .5),
      y: Math.round(Stage.centerY - this.maskHeight * .5 - marginBottom + this.maskY)
    });

    this.mask_backs.forEach(item => {
      TweenLite.set(item.resizeHolder, {
        x: Math.round(this.maskWidth * .5 - Stage.centerX),
        y: Math.round(this.maskHeight * .5 + marginBottom - Stage.centerY - this.maskY)
      });
    });
  }

  slideTo(id)
  {
    this.currentSection = id;

    this.page.swiper.slideTo(this.currentSection, 0);

    const _p = this.currentSection / (this._totalItems - 1);

    if (this.slideTween) this.slideTween.kill();

    this.slide_tl = new TimelineLite({paused:true});
    this.slide_tl.to(this, 1, { progress: _p, ease: Power2.easeInOut });

    this.slideTween = TweenLite.to(this.slide_tl, 4, { progress: 1, ease: Power4.easeOut });

    this.bullets.select(this.currentSection);

  }


  resizeAnimations()
  {
    this.back_anim.setAnimations();
    this.front_anim.setAnimations();
    this.progress = this.progress;
  }

  resize()
  {
    this._isResizing = true;

    this.maskHeight = Stage.height * .6;
    this.maskWidth = this.maskHeight * 600 / 600;
    this.setMaskPos();

    this.sections.forEach( (section) => {
      section.resize();
      //  console.log(section);
    });


    this.resizeAnimations();

    this.bullets.resize();

    this._isResizing = false;

  }

}
