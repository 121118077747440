export function careers($) {
    if ($(window).width() > 768) {
        setTimeout(function() {
            $('.grid').isotope({
                layoutMode: 'masonryHorizontal',
                itemSelector: '.grid-item',
                masonryHorizontal: {
                    rowHeight: ($(window).height() / 3)
                }
            });
        }, 500);

        var slider = document.querySelector('.masonry--slider');
        var isDown = false;
        var startX;
        var scrollLeft;
        var totalSL = 0,
            scrollInterval,
            leaveTimeout;

        slider.addEventListener('mousedown', (e) => {
            isDown = true;
            slider.classList.add('active');
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
            clearInterval(scrollInterval);
        });
        slider.addEventListener('mouseleave', () => {
            isDown = false;
            slider.classList.remove('active');
            leaveTimeout = setTimeout(function() {
                clearInterval(scrollInterval);
                clearTimeout(leaveTimeout);
                $('.cursor .link-arrow').removeClass('scroller left');
            }, 55);
        });
        slider.addEventListener('mouseup', () => {
            isDown = false;
            slider.classList.remove('active');
            clearInterval(scrollInterval);
        });
        slider.addEventListener('mousemove', (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 3; //scroll-fast
            slider.scrollLeft = scrollLeft - walk;
            totalSL = slider.scrollLeft;
        });


        $('.masonry--slider').on('mousemove',
            _.debounce(function(e) {
                if ($(window).width() > 768) {
                    var $this = $(this);
                    // console.log(e);
                    var linkArrow = $('.cursor .link-arrow');

                    TweenMax.to(linkArrow, 0.01, {
                        x: (e.pageX - 4),
                        y: (e.pageY - 3)
                    });
                    $('.cursor .link-arrow').addClass('scroller');


                    if (e.screenX > $(window).width() / 2 && !$this.hasClass('active')) {
                        $('.cursor .link-arrow').removeClass('left');
                        clearInterval(scrollInterval);
                        scrollInterval = setInterval(function() {
                            // $this.stop().animate({
                            //   scrollLeft: totalSL
                            // }, 9);
                            $this.scrollLeft(totalSL);
                            if (totalSL < $this.find('.grid').innerWidth() - $(window).width()) {
                                totalSL += 5;
                            }
                            // console.log(totalSL);
                        }, 20);
                    }
                    if (e.screenX < $(window).width() / 2 && !$this.hasClass('active')) {
                        $('.cursor .link-arrow').addClass('left');
                        clearInterval(scrollInterval);
                        scrollInterval = setInterval(function() {
                            // $this.stop().animate({
                            //   scrollLeft: totalSL
                            // }, 9);
                            $this.scrollLeft(totalSL);
                            if (totalSL > 0) {
                                totalSL -= 5;
                            }
                            // console.log(totalSL);
                        }, 20);
                    }
                }
            }, 10)
        );
    } else {
      const swiperMasonry = new Swiper.default('#masonry--slider', {
          spaceBetween: 0,
          speed: 2000,
          slidesPerView: 'auto',
          loop: true,
          freeMode: true,
          centeredSlides: true,
          autoplay: {
              delay: 1500,
              disableOnInteraction: false
          },
      });
    }
}
