import jQuery from "jquery";
require('./slick.min');
var $ = jQuery;

    $(document).ready(function(){
      
        var seo_header = $(".seo--header").innerHeight();
        $(".header-placeholder").height(seo_header);
       

        $('.strategic-card .client-comment').on('click', function(e){
            
            // $(this).siblings('.client-thought').addClass('active')
            $(this).parent('.strategic-card').find('.client-thought').addClass('active')

        })

        $('.client-thought .close').on('click', function(e){
           
            e.stopPropagation();
            $(this).closest('.client-thought').removeClass('active')
        })

        $("#contact_us, #contact_button, #free_audit").on("click", function (e) {
          console.log($("#contactForm"));
          e.preventDefault();
            $("html, body").animate(
              {
                scrollTop: $("#contactForm").offset().top - 100,
              },
              800
            );
          return false;
        });

        var isiDevice = / iPod| iPad| iPhone/i.test(
          navigator.userAgent.toLowerCase()
        );
        if (isiDevice) {
          document.addEventListener(
            "touchmove",
            function (event) {
              if (event.scale !== 1) {
                event.preventDefault();
              }
            },
            { passive: false }
          );
        }
    

        $('.agency--container-strategic').slick({
            infinite: false,
            dots:true,
            arrows:false,
            slidesToShow: 3,
            slidesToScroll: 1,
            rows:0,
            responsive: [
                {
                  breakpoint: 769,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    arrows:true,
                    dots: false
                  }
                }
            ]

        });

        $('.clients--container-card').slick({
            infinite: false,
            dots:true,
            arrows:false,
            slidesToShow: 5,
            slidesToScroll: 5,
            rows:0,
            responsive: [
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: false,
                    arrows:true,
                    dots: false
                  }
                }
            ]

        });

        $('.banner--slider').slick({
          infinite: true,
          dots:true,
          arrows:false,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay:true,
          rows:0,
          fade: true,
          // speed: 800,
          autoplaySpeed: 5000,
          cssEase: 'linear',
          responsive: [
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
                  arrows:false,
                  dots: true,
                }
              }
          ]

      });

        $("#name_seo").on("keypress keyup", function (event) {
            var inputValue = event.which;
            if (
              !(inputValue >= 65 && inputValue <= 122) &&
              inputValue != 32 &&
              inputValue != 0
            ) {
              event.preventDefault();
            }
            var checkText = $(this).val();
            $(this).val(checkText.replace(/[^a-zA-Z ]/g, ""));
          });
    
          $("#contact_seo").on("keypress keyup", function (event) {
            
              if (event.which < 48 || event.which > 57) {
                event.preventDefault();
              }
            
          });

          $("#city_seo").on("keypress keyup", function (event) {
            var inputValue = event.which;
            if (
              !(inputValue >= 65 && inputValue <= 122) &&
              inputValue != 32 &&
              inputValue != 0
            ) {
              event.preventDefault();
            }
            var checkText = $(this).val();
            $(this).val(checkText.replace(/[^a-zA-Z ]/g, ""));
          });

          $('#form').on('submit', function (e) {
            e.preventDefault(); 
            var name = $('input[name="seoname"]').val()
            var contryCode = $('#countryCode').val()
            var contact_number = $('input[name="contact"]').val()
            var email = $('input[name="email"]').val()
            var city = $('input[name="city"]').val()
            var tnc = $("#contactCheck").is(':checked');
            var emailRegex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
            var validName = true;
            var validNumber = true;
            var validEmail = true;
            var validCity = true;
            var tncChecked = true;

            if (name == '') {
                $('#name_error').html('This field is required')
                validName = false;
            }
            else{
                $('#name_error').html('')
                validName = true;
            }
            if (contact_number == '') {
                $('#contact_error').html("Please enter valid mobile number");
                validNumber = false
                
            } else {
                $('#contact_error').html("");
                validNumber = true;  
            }
            if (!emailRegex.test(email)) {
                $('#email_error').html('Please enter a valid email address');
                validEmail = false;
            } 
            else {
                $('#email_error').html('');
                validEmail = true;
            }
            if (city == '') {
                $('#city_error').html('This field is required')
                validCity = false;
            }
            else{
                $('#city_error').html('')
                validCity = true;
            }
          
                if(!tnc) {
                  $('#checkboxerror').html("This field is required");
                  tncChecked = false;
                } else {
                  $('#checkboxerror').html("");
                  tncChecked = true;
                }

                if(validName && validEmail && validNumber && validCity && tncChecked){
                    
                    var formData = {
                        
                        name_seo: name,
                        contact_seo : contact_number,
                        email_seo : email,
                        city_seo : city,
                        campaign_id:1,
                        contry_code: contryCode,
                        
                    };
                    var serializedData = $.param(formData);

                    $.ajax({
                       
                        url: 'https://newsite.pivotroots.com/api/get-lead-data', //uat api url
                        // url: 'https://pivotroots.com/api/get-lead-data', //live api url
                        type: 'POST',
                        dataType: 'json',
                        headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
                        data: serializedData,
                        
                        success: function(response) {
                            
                            if (response.success) {
                                window.location.href = '/thank-you'
                                console.log('form submitted sucessfully');
                               
                                $("#form").trigger('reset');
                            } else {
                                
                               
                            }
                        },
                        error: function() {
                            console.log('response');
                            alert('An error occurred while submitting the lead.');
                        }
                    });
                }
              
          })
    })