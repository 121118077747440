import Stage from "../../vendorjs/Stage";

export default class Bullets
{
  constructor(carrousel)
  {
    // console.log('init', this);
    this.carrousel = carrousel;
    this.dom = carrousel.dom.querySelector('.carrousel__bullets');
    //this.activeBullet = this.dom.querySelector('.carrousel__bullets__bullet.active');
    this.bullets = Array.from(this.dom.querySelectorAll('.carrousel__bullets__bullet'));
    this.activeID = undefined;

    this.bullets.forEach((bullet, i) => {
      bullet._id = i;
      bullet.addEventListener('click', (e)=> {
        const id = e.currentTarget._id;
        this.carrousel.slideTo(id);
        if (id > this.activeID) {
        }
        $('.carrousel__bullets__bullet').removeClass('active');
        bullet.className += " active";
      });
    });

  }

  select(id)
  {
    this.activeID = id;
    this.bullets.forEach((bullet, i) => {
      let pos = i - id;
      let m1 = Stage.height*.02;
      let m2 = Stage.height*.04;
      if (pos < 0) m1 = -m1;
      else if (pos == 0) m1 = 0;
     //TweenLite.to(bullet, 1, { x:m1+m2*pos, ease:Power4.easeInOut });
    });
      // const col = this.bullets[id].style.backgroundColor;
      // TweenLite.to($('.carrousel__bullets__bullet.active'), .5, { delay:.5, backgroundColor:col, ease:Power1.easeOut });
  }


  resize()
  {
    this.bullets.forEach((bullet, i) => {
      let pos = i - this.activeID;
      let m1 = Stage.height * .02;
      let m2 = Stage.height * .04;
      if (pos < 0) m1 = -m1;
      else if (pos == 0) m1 = 0;
      //TweenLite.set(bullet, { x: m1 + m2 * pos });
    });
  }

}
