export function team($) {
  var totalTeam = $('.team--all .node').length,
      nodeWH = $(window).width() > 500 ? 200 : ($(window).width() / 2 - 28),
      windowWidth = $(window).width(),
      totalInRow = Math.floor(windowWidth / nodeWH),
      totalRows = Math.round(totalTeam / totalInRow),
      maxCirF = $(window).width() > 500 ? 155 : ($(window).width() / 2 - 30),
      irr = 20;
  // declarations
  var positions = [];

  // Returns a random integer between min (included) and max (excluded)
  // Using Math.round() will give you a non-uniform distribution!
  function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  // generate random positions
  function generatePositionsArray(maxX, maxY, safeRadius, irregularity) {
      // declarations
      var positionsArray = [];
      var r, c;
      var rows;
      var columns;
      // count the amount of rows and columns
      rows = Math.floor(maxY / safeRadius);
      columns = Math.floor(maxX / safeRadius);
      // loop through rows
      for (r = 1; r <= rows; r += 1) {
          // loop through columns
          for (c = 1; c <= columns; c += 1) {
              // populate array with point object
              positionsArray.push({
                  x: Math.round(maxX * c / columns) + getRandomInt(irregularity * -1, irregularity),
                  y: Math.round(maxY * r / rows) + getRandomInt(irregularity * -1, irregularity)
              });
          }
      }
      // return array
      return positionsArray;
  }
  positions = generatePositionsArray(($(window).width() - 50), 70000, nodeWH, irr);

  // get random position from positions array
  function getRandomPosition(array, removeTaken) {
      // declarations
      var randomIndex;
      var coordinates;
      // get random index
      randomIndex = getRandomInt(0, array.length - 1);
      // get random item from array
      coordinates = array[randomIndex];
      // check if remove taken
      if (removeTaken) {
          // remove element from array
          array.splice(randomIndex, 1);
      }
      // return position
      return coordinates;
  }

  getRandomPosition(positions, true);

  $('.team--all .node').each(function(idx, ele) {
    var maxCirF = 120;
    var randInt = getRandomInt(150, maxCirF);
    $(this).css({
      position: 'absolute',
      left: positions[idx].x - ($(this).width()),
      top: positions[idx].y - ($(this).height()),
      'transition-delay': (Math.floor(Math.random() * 101) + 100) + 'ms',
      width: randInt,
      height: randInt,
    });
    $(this).attr('actual-X', positions[idx].x - ($(this).width()));
    $(this).attr('actual-Y', positions[idx].y - ($(this).height()));

    $(this).find('.image-container').append('<img src="./images/bg-primary-green.webp" class="bg-image">')
  });

  $('.team--all').css({
    // height: (totalRows * (nodeWH + irr)) + maxCirF,
    height: $('.team--all .node:last-child').offset().top + $('.team--all .node:last-child').height() + 50 - $('.team--container').offset().top
  });

  $('.team--all .node').hover(function(e) {
    var mainHover = $(this);
    var eventCapture = e;
    mainHover.addClass('open');

    $(this).each(function() {
      var eachHover = $(this);
      var maxCirF = 155;
      var randInt = getRandomInt(150, maxCirF);
      $(this).css({
        width: randInt,
        height: randInt,
      });

      if(eachHover.offset().left > eventCapture.pageX &&  eachHover.offset().top < eventCapture.pageY) {
        eachHover.not(mainHover).addClass('move-pXmY');
      }
      if(eachHover.offset().left < eventCapture.pageX &&  eachHover.offset().top < eventCapture.pageY) {
        eachHover.not(mainHover).addClass('move-mXmY');

      }
      if(eachHover.offset().left < eventCapture.pageX &&  eachHover.offset().top > eventCapture.pageY) {
        eachHover.not(mainHover).addClass('move-mXpY');
      }
      if(eachHover.offset().left > eventCapture.pageX &&  eachHover.offset().top > eventCapture.pageY) {
        eachHover.not(mainHover).addClass('move-pXpY');
      }
    });
  }, function() {
    $('.node').removeClass('open move-mXmY move-pXmY move-pXpY move-mXpY');
    var maxCirF = 120;
    var randInt = getRandomInt(150, maxCirF);
    $(this).css({
      width: randInt,
      height: randInt,
    });
  });

  var scene = document.getElementById('scene');
  if (scene) {
    var parallaxInstance = new Parallax(scene, {
      // relativeInput: true,
      limitY: 0.2,
      hoverOnly: true
    });
  }

  var lastScrollTop = 0;
  $(window).scroll(function() {
    scrollFunc();
  });

  function scrollFunc() {
    var st = $(window).scrollTop();
    $('.team--all .node').each(function() {
      var aCt = parseInt($(this).attr('actual-Y')) + $('.team--all').offset().top;
      if (st > lastScrollTop){
        // downscroll code
        if (aCt < ($(window).scrollTop() + $(window).height())) {
          $(this).addClass('show');
        }
        if (aCt < $(window).scrollTop() - 50) {
          $(this).addClass('show small');
        }
      } else {
        // upscroll code
        if (aCt > ($(window).scrollTop() + $(window).height())) {
          $(this).removeClass('show');
        }
        if (aCt > $(window).scrollTop()) {
          $(this).removeClass('small');
        }
      }
    });
    $('[anim-target]').each(function() {
      if (st > lastScrollTop){
        // downscroll code
        if ($(this).offset().top < $(window).scrollTop() + $(window).height() - ($(window).height() / 2)) {
          $(this).addClass('show');
        }
      } else {
        // upscroll code
      }
    });
    lastScrollTop = st;
  }

}
