// import Vuebar from 'vuebar';
export function ourWorkDetail($) {

    var scrollableElement = document.body; //document.getElementById('scrollableElement');

    scrollableElement.addEventListener('wheel', checkScrollDirection);

    function checkScrollDirection(event) {
        $('.works--detail-main').css({
            paddingBottom: $('.works--detail--footer').innerHeight()
        });
        if (checkScrollDirectionIsUp(event)) {
            // console.log('UP');
            var st = $('.works--detail-mainwrapper').scrollTop();
            if (st == 0) {
                resetScroll();
            }
        } else {
            // console.log('Down');
            $('.works--detail-mainwrapper').removeClass('fade-enter');
            $('.works--detail-mainwrapper').addClass('fade-enter');
        }
    }

    // $('.works--detail-preview').on('mousewheel', function(e){
    //   e.preventDefault();
    //   $('.works--detail-main').css({
    //     paddingBottom: $('.works--detail--footer').innerHeight() + 30
    //   });
    //   if(e.originalEvent.wheelDelta < 0) {
    //     // console.log('down');
    //     $('.works--detail-mainwrapper').removeClass('fade-enter');
    //     $('.works--detail-mainwrapper').addClass('fade-enter');
    //     // Vue.use(Vuebar);
    //   } else {
    //     // console.log('up');
    //     resetScroll();
    //   }
    // });

    $('body:not(.works--detail-mainwrapper)').click(function() {
        resetScroll();
    });

    $('.works--detail-mainwrapper').on('scroll', function() {
        var st = $('.works--detail-mainwrapper').scrollTop();
        if (st >= $('.works--detail-main').innerHeight() - $(window).height()) {
            $('.works--detail--footer').addClass('show');
        } else {
            $('.works--detail--footer').removeClass('show');
        }
    });


    function resetScroll() {
        $('.works--detail-mainwrapper').removeClass('fade-enter');
        $('.works--detail--footer').removeClass('show');
        setTimeout(function() {
            $('.works--detail-mainwrapper').scrollTop(0);
        }, 500);
    }

    function checkScrollDirectionIsUp(event) {
        if (event.wheelDelta) {
            return event.wheelDelta > 0;
        }
        return event.deltaY < 0;
    }



}