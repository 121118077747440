import Stage from "../../../vendorjs/Stage";

export default class BackAnimations
{
  constructor(carrousel)
  {
    this.carrousel = carrousel;
    // this.setAnimations();
  }

  setAnimations()
  {
    if (this.main_tl) {
      this.main_tl.kill();
      this.main_tl.invalidate();
      this.main_tl = null;
    }
    // console.log('setAnimations');
    this.main_tl = new TimelineLite({ paused: true });

    this.carrousel.back_items.forEach((item, i) => {

      const _tl = new TimelineLite();

      // slide translation
      let _firstX = (i == 0) ? 0 : Stage.height;
      let _lastX = (i == this.carrousel.mask_backs.length - 1) ? 0 : -Stage.height;
      _tl.fromTo([item.holder, item.bgColor], 1, { y: _firstX }, { y: 0, ease: Power1.easeInOut }, 0);
      _tl.to([item.holder, item.bgColor], 1, { y: _lastX, ease: Power1.easeInOut }, 1);

      // bg stabilisation
      _tl.fromTo(item.image, 1, { y: -_firstX }, { y: 0, ease: Power1.easeInOut }, 0);
      _tl.to(item.image, 1, { y: -_lastX, ease: Power1.easeInOut }, 1);

      // bg parallax
      let _center = 1;
      let _delay = .05;
      let _dur = 1 - _delay * 2;
      let _prlxWidth = Stage.height * .33;
      // console.log(_center);
      // console.log(_prlxWidth);
      _tl.fromTo(item.imgPrlx, _dur, { y: _prlxWidth }, { y: 0, ease: Power2.easeInOut }, _delay);
      _tl.to(item.imgPrlx, _dur, { y: -_prlxWidth, ease: Power2.easeInOut }, _center + _delay);

      // bg alpha
      _delay = .2;
      _dur = 1 - _delay * 2;

      _tl.fromTo(item.imgPrlx, _dur, { autoAlpha: 0 }, { autoAlpha: 1, ease: Power1.easeInOut }, _delay);
      _tl.to(item.imgPrlx, _dur, { autoAlpha: 0, ease: Power1.easeInOut }, _center + _delay);

      // titles
      _delay = .05;
      _dur = (item.bgLines.length > 1) ? .3 : .4;
      _center = (item.bgLines.length > 1) ? 1 - .15 * .5 : 1;

      if (item.bgTitle) {
        item.bgLines.forEach((line, j) => {
          _tl.fromTo(line, _dur, { y: Stage.height }, { y: 0, ease: Power2.easeOut }, _center - _delay - _dur + j * .15);
          if (i < this.carrousel.back_items.length - 1)
            _tl.to(line, _dur, { y: -Stage.height , ease: Power2.easeIn }, _center + _delay + j * .15);
        });
      }

      // masked stuffs
      // console.log(this.carrousel.mask_backs[i]);
      const mask_back = this.carrousel.mask_backs[i];
      // slide translation
      _center = 1;
      _delay = .15;
      _dur = 1 - _delay * 2;
    //  console.log(_dur);
      _firstX = (i == 0) ? 0 : this.carrousel.maskWidth + 2;
      _lastX = (i == this.carrousel.mask_backs.length - 1) ? 0 : this.carrousel.maskWidth - 2;
      _tl.fromTo(mask_back.holder, _dur, { y: _firstX }, { y: 0, ease: Power2.easeInOut }, _delay);
      _tl.to(mask_back.holder, _dur, { y: -_lastX, ease: Power2.easeInOut }, _center + _delay);
      // console.log(_dur, _delay, _center - _delay - _dur );

      // bg stabilisation
      _tl.fromTo(mask_back.image, _dur, { y: - _firstX }, { y: 0, ease: Power2.easeInOut }, _delay);
      _tl.to(mask_back.image, _dur, { y: this.carrousel.maskWidth - 2, ease: Power2.easeInOut }, _center + _delay);

      // bg parallax
      _center = 1;
      _delay = .05;
      _dur = 1 - _delay * 2;

      _tl.fromTo(mask_back.imgPrlx, _dur, { y: _prlxWidth }, {y: 0, ease: Power2.easeInOut }, _delay);
      _tl.to(mask_back.imgPrlx, _dur, { y: -_prlxWidth, ease: Power2.easeInOut }, _center + _delay);

      // do not pass 2
      if (_tl.duration() > 2) console.log('_tl too long:', _tl.duration());

      this.main_tl.add(_tl, i);

    });

  }

}
