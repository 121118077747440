export function canvasParticles() {

  var canvas = document.getElementById('particle');

  if (canvas) {
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    var c = canvas.getContext('2d');

    window.addEventListener('resize', function() {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;

      dynamicCircles();
    });

    function Circle(x, y, dx, dy, radius) {
      this.x = x;
      this.y = y;
      this.dx = dx;
      this.dy = dy;
      this.radius = radius;
      this.minRadius = radius;
      this.maxRadius = radius * 15;
      this.color = '#CCDB2C';

      this.draw = function() {
        c.beginPath();
        c.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
        c.fillStyle = this.color;
        c.fill();
      }

      this.update = function() {
        if (this.x > (innerWidth - this.radius) || this.x < this.radius) {
          this.dx = -this.dx;
        }
        if (this.y > (innerHeight - this.radius) || this.y < this.radius) {
          this.dy = -this.dy;
        }

        this.x += this.dx;
        this.y += this.dy;

        this.draw();
      }
    }

    var circleArray = [],
    totalCircles = 2;
    function dynamicCircles() {
      circleArray = [];

      for (var i = 0; i < totalCircles; i++) {
        var radius = 30;
        var velocity = 2;
        if (i % 2) {
          radius = 55;
          velocity = 1;
        }
        var x = Math.random() * (window.innerWidth - radius * 2) + radius,
        y = Math.random() * (window.innerHeight - radius * 2) + radius,
        dx = (Math.random() - 0.5) * 8,
        dy = (Math.random() - 0.5) * 8;
        circleArray.push(new Circle(x, y, velocity, velocity, radius));
      }
    }

    function animate() {
      requestAnimationFrame(animate);
      c.clearRect(0, 0, innerWidth, innerHeight);

      for (var i = 0; i < circleArray.length; i++) {
        circleArray[i].update();
      }
    }

    function getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    animate();
    dynamicCircles();
  }

}
