export function horizontalScroller($) {

    const swiperMarquee = new Swiper.default('#aboutMarquee', {
        spaceBetween: 0,
        speed: 2000,
        slidesPerView: 'auto',
        loop: true,
        freeMode: true,
        centeredSlides: true,
        autoplay: {
            delay: 500,
            disableOnInteraction: false
        },
    });

}
